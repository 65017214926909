:root {
    --bg-color: rgba(255, 255, 255, 0.795)
}

.post {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column;
    background-color: var(--bg-color);
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
    
}

.title {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    text-align: center;
    justify-content: center;
}

.post-data {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
}

.knowledgebase {
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: column;

}

.create-post {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
    background-color: var(--bg-color);
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
    flex-wrap: wrap;
}

.post-container {
    max-width: 75%;
}

.search {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 5rem;
    background-color: var(--bg-color);
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
}

.ql-editor {
    min-height: 300px;
}

.quill  .ql-container{
    display: flex;
    flex-wrap: wrap-reverse;
}

.resources {
    margin: 10px 10px 30px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content {
    min-width: 50vw;
}

.comments {
    display: flex;
    flex-direction: column;
}

.commentsInput {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 10px 10px 10px 10px;
}

@media screen and (max-width: 800px) {
   .post-container {
    max-width: 100%;
   }
}
