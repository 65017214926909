.video  {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 55rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
    
    