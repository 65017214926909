#web-content {
    min-height: 80vw;
}

.logo { 
    margin-top: 10px;
    float: left;
    margin-right: 10px;
    display: block;
}

@media screen and (max-width: 900px) {
    .logo {
        display: none;
    }
}

.Footer {
    margin: 0;
    position: relative;
    z-index: 1001;
    bottom: 0;
    height: 10px;
    width: 100%;
}


/* Nav bar reference: https://www.w3schools.com/css/css_navbar_horizontal.asp */
.footer-ul {
    z-index: 1000;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: white;
    position: relative;
    bottom: 10px;
    width: 100%;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
}

ul Button {
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    
    float: right;
    font-family: var(--poppinsBold);
    color: white;
}

ul Button h1 {
    font-size: 1.25rem;
    padding: 5px;
}

li {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    float: left;
}

li a {
    display: block;
    color: black;
    font-family: var(--poppinsBold);
    font-size: 1.25rem;
    text-align: center;
    padding: 0px 15px;
    text-decoration: none;
    font-weight: bold;
}

li a span:hover {
    border-bottom: 5px blue solid;
}


@media screen and (max-width: 800px) {
    li a {
        font-size: 1rem;
        margin-top: 5px;
        padding: 0px 10px;
    }

    ul Button h1 {
        font-size: 1rem;
    }
}

@media screen and (max-width: 400px) {
    li a {
        font-size: .75rem;
        padding: 0px 5px;
    }

    ul Button h1 {
        font-size: .75rem;
        padding: 0px;
    }
}