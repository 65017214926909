.content-container-inner {
  width: 100%;
  margin: auto;
}


/* @media screen and (max-width: 800px) {
  .content-container-inner {
    width: 50%;
  }
} */

.content-container{
  height: 55rem;
}

.content {
  margin-top: 10rem;
  padding-bottom: 5rem;
}

.content-container {
  margin-top: 5rem;
}

.closing {
  margin-bottom: 5rem;
}

.titlecontainer {
  margin-top: 10px;
  width: 60%;
  float: left;
  background-color: white;
}

.title {
  font-size: 6rem;
  font-family: var(--poppinsBold);
}

/* Reference: https://codepen.io/alvarotrigo/pen/PoKMyNO */
.animate-charcter {
  background-image: linear-gradient(
      -225deg,
      blue 0%,
      green 49%,
      yellow 67%,
      blue 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 3.5s linear infinite;
  display: inline-block;
}

@keyframes textclip {
  to {
      background-position: 200% center;
  }
}


@keyframes scale {
  100% {
      transform: scale(1);
  }
}

.blurdiv {
  width: 100%;
  background-color: lightgrey;
  border-radius: 50px;

  padding: 50px;

  margin-bottom: 50px;
  margin-top: 50px;
  text-align: center;
}

.blurdiv .blur h1 {
  font-family: "Montserrat Medium";
  width: 100%;
  text-align: center;
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}


/* Reference: https://codepen.io/alvarotrigo/pen/ExvqdNa */
.blur-text {
  font-size: 4rem;
}

.blur-text span {
  margin-right: 10px;
  display: inline-block;
  opacity: 0;
  filter: blur(4px);
}

.blurdiv,
.blurdiv form {
  margin-top: 20px;
}

.blur span:nth-child(1) {
  animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.blur span:nth-child(2) {
  animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.blur span:nth-child(3) {
  animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.blur span:nth-child(4) {
  animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.blur span:nth-child(5) {
  animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.blur span:nth-child(6) {
  animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.blur span:nth-child(7) {
  animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.blur span:nth-child(8) {
  animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.blur span:nth-child(9) {
  animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.blur span:nth-child(10) {
  animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.blur span:nth-child(11) {
  animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.blur span:nth-child(12) {
  animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.blur span:nth-child(13) {
  animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.blur span:nth-child(14) {
  animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.blur span:nth-child(15) {
  animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.blur span:nth-child(16) {
  animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.blur span:nth-child(17) {
  animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.blur span:nth-child(18) {
  animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

@keyframes fade-in {
  100% {
      opacity: 1;
      filter: blur(0);
  }
}


@media screen and (max-width: 1300px) {
  .title {
      font-size: 5rem;
  }
  .blur-text {
    font-size: 3rem;
  }
}

@media screen and (max-width: 800px) {
  .titlecontainer {
      width: 100%;
      text-align: center;
  }
  .title {
      font-size: 4rem;
  }
  .blur-text {
    font-size: 2rem;
  }
  .content-container{
    height: 55rem;
  }
}

@media screen and (max-width: 400px) {
  .titlecontainer {
      width: 100%;
      text-align: center;
  }
 
  .title {
      font-size: 3rem;
  } 
  .blur-text {
    font-size: 1rem;
  }
}

@media screen and (max-width: 380px) {
  .content-container{
    height: 45rem;
  }
}

/**
Shadow fade
*/

.shadow-sm,.shadow-xl {
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow)
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0,0,0,.1),0 8px 10px -6px rgba(0,0,0,.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),0 8px 10px -6px var(--tw-shadow-color)
}